<template>
    <div class="container-fluid bg_gray px-0 py-0 mt-2 rounded-top">
        <div class="row">
            <div @click="changeTitle(index)" :class="['col', (item.selected) ? 'bg-white rounded-top border-bottom-0 border-color' : 'border-bottom', 'pt-1']" v-for="(item, index) in array_titletabs" :key="index">
                <span :class="[(item.selected) ? 'text-black' : 'text-primary', ]">{{item.name}}</span>
            </div>
            <div :class="['col', 'border-bottom', 'pt-1']" v-if="array_titletabs.length == 1">
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ki-titletabs',
    props: {
        array_titletabs: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            title_selected : null
        };
    },

    mounted(){
        for (let i in this.array_titletabs) {
            if(this.array_titletabs[i].selected){
                this.title_selected = this.array_titletabs[i].name
            }
        }
    },

    methods: {
        changeTitle(new_title) {
            for (let i in this.array_titletabs) {
                if(this.array_titletabs[i].selected){
                    this.array_titletabs[i].selected = false
                }
            }
            this.title_selected = this.array_titletabs[new_title].name
            this.array_titletabs[new_title].selected = true
            this.$emit('changeTitle', this.title_selected);
        },
    },
};
</script>

<style lang="scss">

    .border-color{
        border-top: solid 1px #DCDDE0;
        border-left: solid 1px #DCDDE0;
        border-right: solid 1px #DCDDE0;
    }



</style>