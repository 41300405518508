<template>
    <div id="config-business" class="container-fluid">
        <div class="row">
            <titles title="Configuración" subtitle="Empresa" ></titles>
            <title-tabs :array_titletabs="title_business"></title-tabs>
	        
            <div class="col-12 bg-white shadow-sm py-3 px-3 rounded border-box">
	            <span class="text-black opensans-semibold ki--description-sm">Información de usuario</span>
            </div>
	        
	        <div class="col-3 bg-white shadow-sm py-3 px-3  rounded border-box my-3">
		        <img width="150" height="150" style="object-fit: scale-down;"
			        :src="user.business_picture" alt="kinver logo">
	        </div>
	        
	        <div class="col-9 bg-white shadow-sm py-3 px-3 rounded border-box mb-4 mt-4">
		        
		        <div class="row">
			        
			        <div class="col-4 text-dark">
				        <span>
					        <strong>
						        Nombre de usuario:
					        </strong>
					        {{ user.username}}
				        </span>
				        <hr class="border border-primary w-100">
			        </div>
			        
			        <div class="col-4 text-dark">
				        <span>
					        <strong>
						        Empresa:
					        </strong>
					        {{ user.business_name }}
				        </span>
				        <hr class="border border-primary w-100">
			        </div>
			        
			        <div class="col-4 text-dark">
				        <span>
					        <strong>
						        Sucursal:
					        </strong>
					        {{ user.store_name }}
				        </span>
				        <hr class="border border-primary w-100">
			        </div>
			        
			        <div class="col-12 text-dark">
			        <span>
				        <strong>
					        Nombre completo:
				        </strong>
				        {{ user.first_name }} {{ user.last_name }} {{ user.second_surname }}
			        </span>
				        <hr class="border border-primary w-100">
			        </div>
			        
			        <div class="col-6 text-dark">
			        <span>
				        <strong>
					        Télefono:
				        </strong>
				        {{ user.phone }}
			        </span>
				        <hr class="border border-primary w-100">
			        </div>
			        
			        <div class="col-6 text-dark">
			        <span>
				        <strong>
					        Correo:
				        </strong>
				        {{ user.email }}
			        </span>
				        <hr class="border border-primary w-100">
			        </div>
			        
		        </div>
		        
		        
	        </div>
	        
	        
        </div>
    </div>
</template>

<script>
    //Components
    import Titles from '../../components/Titles.vue'
    import TitleTabs from '../../components/TitleTabs.vue'
    import {mapGetters} from "vuex";

export default {
    name: 'ki-config-business',
    components: {
        'titles': Titles,
        'title-tabs': TitleTabs,
    },
    data() {
        return {
            title_business: [
                { name: 'Configuración de empresa', selected: true },
            ],
           
        };
    },
    computed: {
	    ...mapGetters({
		    user: 'getSession',
		    
	    })
    },
    created(){
        for (let i in this.title_business) {
            if(this.title_business[i].selected){
                this.titletab_active = this.title_business[i].name
            }
        }
    }
};
</script>

<style lang="scss" scoped>

</style>