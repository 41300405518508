<template>
    <div class="container-fluid pb-2">
        <div class="row d-flex justify-content-start align-items-center">
            <span :class="['opensans-semibold', 'kn--description-sm', (subtitle != null) ? 'text-secondary' : 'text-dark']">{{title}}</span>
            <div class="px-3" v-if="subtitle != null">
                <img :src="arrow_right" class="img-fluid">
            </div>
            <span v-if="subtitle != null" :class="['opensans-semibold', 'kn--description-sm', (othertitle != null) ? 'text-secondary' : 'text-dark']">{{subtitle}}</span>
            <div class="px-3" v-if="othertitle != null">
                <img :src="arrow_right" class="img-fluid">
            </div>
            <span v-if="othertitle != null" :class="['opensans-semibold', 'kn--description-sm', 'text-dark']">{{othertitle}}</span>
        </div>
    </div>
</template>

<script>
import arrow_right from "../assets/icons/arrow_right.png";
export default {
    name: 'ki-title',
    props: {
        title: {
            type: String,
            required: true,
        },
        subtitle: {
            type: String,
            required: false,
            default: null
        },
        othertitle: {
            type: String,
            required: false,
            default: null
        }
    },
    data() {
        return {
            arrow_right,
        };
    },

    mounted() {
        
    },

    methods: {
        
    },
};
</script>

<style lang="scss" scoped>

</style>